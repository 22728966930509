<!-- 此页面差那后台数据，改table字段，根据接口写方法 -->
<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     align="center"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      prop="examineDate"
      label="检测日期"
      align="center">
    </el-table-column>
    <el-table-column
      prop="orgName"
      label="检测机构"
      show-overflow-tooltip
      align="center">
    </el-table-column>
    <el-table-column
      prop="mobile"
      label="检测地区"
      align="center"
      show-overflow-tooltip>
      <template slot-scope="scope">
       {{ scope.row.provinceName}}{{scope.row.cityName}}
      </template>
    </el-table-column>
    <el-table-column
      prop="examineOrgName"
      label="受检机构"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="type"
      label="体检类目"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      align="center"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <span style="color:#00cccc;cursor: pointer;" @click="view(scope.row)">查看详情</span>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 目前数量少 后期在加-->
  <!-- <div class="page">
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div> -->
</div>
</template>

<script>
import {getRecordList} from '@/api/userManage.js'
  export default {
    data() {
      return {
        tableData: [],//表格数据
        multipleSelection: [],// tableCheckboxValue
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 

      }
    },
     created(){
        if(this.$route.query.id){
             //console.log("表格id",this.$route.query.id);
             this.getData(this.$route.query.id);
        }
     },

    //监听勾选值
    watch:{
        multipleSelection:{
            handler(pre){
              var tem = pre;
              if(tem.length==0){
                 this.$parent.block = true;
              }
              if(tem.length==1){
                this.$parent.block = false;
                //this.$parent.downStatus= true;
                this.$parent.downloadId= tem[0].id;
               }
             if(tem.length>1){
                 this.$parent.block = true;
                 this.$message({
                  message: '当前仅支持下载单个数据！',
                  type: 'warning'
                });
              }
            },
            deep:true,
            immediate:true
        }
    },
    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(id){
              const rLoading = this.openLoading();// 开始loading
              getRecordList({
              id:id
            }).then((res)=>{
              if(res.data.status == 200){
                 this.tableData = res.data.data;
                 rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
        this.multipleSelection.length = 0;//clear
        this.multipleSelection = val;
      },

      // 分页相关
      SizeChange(val){
        // pageSize 改变时会触发  回调参数 每页条数
       // console.log("每页多少条",val);
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage);//更新数据
      },
      CurrentChange(val){
        // currentPage 改变时会触发  回调参数 当前页
       // console.log("当前页面",val);
        this.currentPage = val;
        this.getData(this.currentPage,this.onlyPage);//更新数据
      },
      prevClick(val){
        // 上一页
        // console.log("上一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage);//更新数据

      },
      nextClick(val){
        // 下一页
        // console.log("下一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage);//更新数据

      },
      //click ROW
      view(e){
        this.$router.push({
            path:'/userList/fileList/report',
            query:{
                dataId:e.id,
                parid:this.$parent.id
               }
           })
        
      }
    },
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>