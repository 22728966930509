<!-- 此页面差那后台数据，改table字段，根据接口写方法 -->
<template>
    <div class="fileList">
    <el-breadcrumb separator="/">
          <el-breadcrumb-item>受检管理</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goback1">受检人档案列表</el-breadcrumb-item>
          <el-breadcrumb-item>档案详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- baseinfo -->
    <div class="baseInfo">
        <div class="delete" @click="remove">删除</div>
        <div class="betwen">
             <span class="blueTitletop">受检人基本信息</span>
             <span class="edit" @click="edit">编辑</span>
        </div>
        <div class="info">
            <div class="infobox">
                <span class="spanfo">姓名：{{baseInfo.examineName}}</span>
                <span class="spanfo" v-if="baseInfo.gender== '0'" style="margin-top:36px">性别：女</span>
                <span class="spanfo" v-if="baseInfo.gender== '1' " style="margin-top:36px">性别：男</span>
            </div>
            <div class="infobox">
                <span class="spanfo">身份证号：{{baseInfo.cardId}}</span>
                <span class="spanfo" style="margin-top:36px">创建日期：</span>
            </div>
            <div class="infobox">
                <span class="spanfo">手机号：{{baseInfo.mobile}}</span>
                <span class="spanfo" style="margin-top:36px">身高：{{baseInfo.height}}</span>
            </div>
            <div class="infobox">
                <span class="spanfo">出生年月：{{baseInfo.birthday}}</span>
                <span class="spanfo" style="margin-top:36px">体重：{{baseInfo.weight}}</span>
            </div>
        </div>
    </div>
    <!-- table -->
    <div class="tablebox">
        <span class="blueTitle">检测记录</span>
        <div class="tableTop">
                <div class="tableTop_left">
                   <div class="downloadReport" v-if="!block" @click="doProReport"><i class="el-icon-tickets" style="margin-right:4px"></i>下载报告</div>
                    <div class="downloadReport_no" v-if="block"><i class="el-icon-tickets" style="margin-right:4px"></i>下载报告</div>
                    <div class="downloadOrgnData" v-if="!block" @click="doSpineOrgData"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载脊柱原始数据</div>
                    <div class="downloadOrgnData_no" v-if="block"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载脊柱原始数据</div>
                    <div class="downloadOrgnData" v-if="!block" @click="doFootOrgData"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载足部原始数据</div>
                    <div class="downloadOrgnData_no" v-if="block"><i class="el-icon-document-copy" style="margin-right:4px"></i>下载足部原始数据</div>
                </div>
                <div class="tableTop_right">
                    <div class="search">
                    <el-input
                            placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            @change="seach"
                            class="elSerch"
                            v-model="seachContent"
                            >
                            <template slot="append" style="background-color: #00CCCC;cursor pointer">搜索</template>
                    </el-input>
                </div>
                </div>
            </div>
        <!-- table -->
        <FileListTable/>
    </div>

    <!-- 编辑弹窗 -->
        <el-dialog title="修改受检者基本信息" width="420px" :visible.sync="editDialog" custom-class="eldia">
                <el-form :model="editForm" :inline="true" label-position="left" label-width="80px" :rules="rules" ref="editForm" style="margin-left:20px">
                        <el-form-item label="姓名" prop="name" >
                            <el-input v-model="editForm.name" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                          <el-form-item label="身份证号" prop="cardId"  >
                            <el-input v-model="editForm.cardId" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号" prop="mobile"  >
                            <el-input v-model="editForm.mobile" autocomplete="off" style="width:160px;"></el-input>
                        </el-form-item>
                        <el-form-item label="创建日期" prop="createTime"  >
                            <el-date-picker v-model="editForm.createTime" type="date" style="width:160px !important;" placeholder="选择创建日期" value-format="yyyy-MM-dd"> </el-date-picker>
                        </el-form-item>
                        <el-form-item label="身高" prop="height" style="margin-left:10px">
                            <el-input v-model="editForm.height" autocomplete="off" style="width:160px;margin-left: -10px !important;"></el-input>
                        </el-form-item>
                         <el-form-item label=" 体重" style="margin-left:10px" prop="weight"  >
                            <el-input v-model="editForm.weight" autocomplete="off" style="width:160px;margin-left: -10px !important;"></el-input>
                        </el-form-item>
                </el-form>
                <!-- footer -->
                <div class="footerSub">
                    <div class="tijiao" @click="cancel">取消</div>
                    <div class="tijiao" @click="save('editForm')">保存</div>
                </div>
        </el-dialog>
</div>
</template>

<script>
import FileListTable from '@/components/table/fileListTable.vue'
import {getBaseInfo,updateReportInfo} from '@/api/userManage.js'
import {getProReport,getSpineData,getFootData} from '@/api/checkTaskDetail.js'
export default {

    beforeRouteLeave(to,from,next){
        if(to.name === 'UserList'){
            to.meta.keepAlive = true
        }
        next()
    },
    components:{
        FileListTable
    },
    data(){
        //phone
        var checkPhone = (rule, value, callback) => {
         let reg =/^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
                if (!value) {
                    callback(new Error('手机号不能为空'));
                } else {
                    if (!reg.test(value)) {
                        callback(new Error('手机号码格式不正确'));
                    }
                      callback();
             }
        };
        return{
            editDialog:false,//编辑控件
            block:true,
            downloadId:null,//下载id
            seachContent:null,//搜索内容
            baseInfo:'',//页面数据
            id:null,
            userId:null,
            editForm:{
                name:null,
                cardId:null,
                mobile:null,
                createTime:null,
                height:null,
                weight:null
            },
            //校验规则
            rules:{
                name: [ { required: true, message: '请填写姓名', trigger: 'blur' }],
                cardId: [ { required: true, message: '请填写身份证号', trigger: 'blur' }],
                mobile:[{ required: true,validator: checkPhone, trigger: 'blur' }],
                pirth:[ { required: true, message: '请选择生日', trigger: 'blur' }],
                gender:[ { required: true, message: '请选择性别', trigger: 'blur' }],
                createTime:[ { required: true, message: '请选择创建日期', trigger: 'blur' }]
            },
        }

    },
    
    mounted(){
        if(this.$route.query.id){
            this.id=this.$route.query.id;
            this.getBaseInforMation(this.$route.query.id);
        }
     },
  
    methods:{
         //面包屑返回
         goback1(){
            this.$router.go(-1);
        },
        getBaseInforMation(id){
            getBaseInfo({
                id:id
            }).then(res=>{
                this.baseInfo=res.data.data;
                this.userId = res.data.data.id;
            }).catch(res=>{
                console.log(res)
            })
        },
        //remove
        remove(){
            this.$message.error('暂不支持');
        },

        //编辑
        edit(){
            this.editDialog = true;
            //回显编辑框
            this.editForm.name = this.baseInfo.examineName;
            this.editForm.cardId = this.baseInfo.cardId;
            this.editForm.mobile = this.baseInfo.mobile;
            this.editForm.createTime = this.baseInfo.createTime;
            this.editForm.height = this.baseInfo.height;
            this.editForm.weight = this.baseInfo.weight;
        },
        //搜索
        seach(e){
            console.log("搜索",e);//current-page:1
        },
        //取消编辑
        cancel(){
            this.editDialog = false;
            this.editForm.name = null;
            this.editForm.cardId = null;
            this.editForm.mobile = null;
            this.editForm.createTime = null;
            this.editForm.height = null;
            this.editForm.weight = null;
        },
        save(formName){
           this.$refs[formName].validate((valid) => {
          if (valid) {
           updateReportInfo({
            id:this.userId,
            cardId:this.editForm.cardId,
            examineName:this.editForm.name,
            height:this.editForm.height,
            mobile:this.editForm.mobile,
            weight:this.editForm.weight
           }).then(res=>{
            if(res.data.status == 200){
                this.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                this.editDialog = false;
                this.getBaseInforMation(this.$route.query.id);//刷新
                this.cancel();//clear
            }else{
                this.$message.error(res.data.message);
            }
           })

          } else {
            this.$message.error('必填项不能为空！');
            return false;
          }
        });
        },
        //下载专业版报告
       doProReport(){
            const loading = this.$loading({
            lock: true,
            text: '下载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });
            getProReport({
                id:this.downloadId
            }).then(res => {
                      if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                      if (res.data.type === 'application/json') {
                        //非进制流文件 在这里
                        const reader = new FileReader()
                        reader.readAsText(res.data)
                        reader.onload = e => {
                        const resData = JSON.parse(reader.result)
                        if(resData.status!=200){
                            loading.close();
                            this.$message.error(resData.message)
                        }
                    }
                 }
                      else{
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        loading.close();
                      }
                        
            }).catch((err) => {
                   console.log("catcherro",err)
            });                
       },
       //下载脊柱原始数据  getSpineData
       doSpineOrgData(){
            const loading = this.$loading({
            lock: true,
            text: '下载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });

            getSpineData({
                id:this.downloadId
            }).then(res => {
                      if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                      if (res.data.type === 'application/json') {
                        //非进制流文件 在这里
                        const reader = new FileReader()
                        reader.readAsText(res.data)
                        reader.onload = e => {
                        const resData = JSON.parse(reader.result)
                        if(resData.status!=200){
                            loading.close();
                            this.$message.error(resData.message)
                        }
                    }
                 }
                      else{
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        loading.close();
                      }
                        
            }).catch((err) => {
                console.log("catcherro",err)
            });         
       },
       //下载足部原始数据
       doFootOrgData(){
            const loading = this.$loading({
            lock: true,
            text: '下载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });

            getFootData({
                id:this.downloadId
            }).then(res => {
                      if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                      if (res.data.type === 'application/json') {
                        //非进制流文件 在这里
                        const reader = new FileReader()
                        reader.readAsText(res.data)
                        reader.onload = e => {
                        const resData = JSON.parse(reader.result)
                        if(resData.status!=200){
                            loading.close();
                            this.$message.error(resData.message)
                        }
                    }
                 }
                    else{
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        loading.close();
                    }
            }).catch((err) => {
                console.log("catcherro",err)
            });            
       },
    }
}
</script>

<style scoped lang="stylus">
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__inner{
    height: .30rem !important;
    font-size: .11rem !important
  }

/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
}

.footerSub
    width: 400px
    margin: 0 auto 0 -20px
    display: flex
    justify-content: space-evenly
    .tijiao
        width:80px
        height:32px
        font-size:16px
        color:#fff;cursor:pointer
        background-color: #00cccc
        line-height: 32px
        border-radius:4px
        text-align: center
.blueTitletop
  font-size: 16px 
  color: #353535
  margin-left: 10px
  line-height: 28px 
  position: relative
  &::before
    width: 2px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:4px
    background-color: #00CCCC
.blueTitle
  font-size: 16px 
  color: #353535
  margin-left: 10px
  line-height: 28px 
  position: relative
  &::before
    width: 2px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:0px
    background-color: #00CCCC
.elSerch>>>.el-input__inner
    border: 1px solid #00CCCC !important
    width: 200px
    border-radius: 4px 0 0 4px

/*冻结样式 */  
.downloadReport_no
    width: 108px
    height: 32px
    background-color:#F4F4F4
    line-height: 32px
    font-size: 14px
    text-align: center
    color:#AEAEAE
    border-radius: 2px
    cursor not-allowed
.downloadOrgnData_no
    width: 142px
    height: 32px
    margin-left: 24px
    background-color:#F4F4F4
    line-height: 32px
    font-size: 14px
    text-align: center
    color: #AEAEAE
    border-radius: 2px
    cursor not-allowed

/deep/.eldia
    padding-left:20px 
/*comstyle */
.fileList
    overflow hidden
    .baseInfo
        width: 100%
        height: 300px 
        margin-top: 15px
        box-sizing: border-box
        padding: 40px 64px 0 40px
        background-color: #fff
        .delete 
            width: 116px 
            height: 32px   
            font-size:14px 
            color: #fff
            background-color: #00CCCC
            line-height: 32px 
            text-align: center
            cursor pointer
        .betwen
            display: flex
            width: 100%
            margin-top: 60px
            justify-content: space-between
            .edit   
                color: #00A3A3
                font-size: 16px
                cursor pointer
        .info 
            margin-top: 34px;
            margin-left: 8px;
            display: flex;
            justify-content: space-between
            .infobox
                width: 240px 
                .spanfo
                    display: block
                    font-size: 14px
                    color: #858585
                    text-align: left 
    .tablebox
        width: 100%
        background-color: #fff
        margin-top: 46px
        box-sizing: border-box
        padding: 40px
        .tableTop 
            display: flex
            justify-content: space-between
            align-items: center
            margin-top: 32px
            .tableTop_left
                display: flex
                .downloadReport
                    width: 108px
                    height: 32px
                    background-color: #00CCCC
                    line-height: 32px
                    font-size: 14px
                    text-align: center
                    color: #ffffff
                    border-radius: 2px
                    cursor pointer
                .downloadOrgnData
                    width: 122px
                    height: 32px
                    margin-left: 24px
                    background-color: #00CCCC
                    line-height: 32px
                    font-size: 14px
                    text-align: center
                    color: #ffffff
                    border-radius: 2px
                    cursor pointer
            .tableTop_right
                display: flex
                .delete
                    width: 64px
                    height: 32px
                    background-color: #FC5555
                    text-align: center
                    line-height:32px
                    color: #fff
                    font-size: 14px
                    margin-left: 16px
                    cursor pointer
                    border-radius: 4px
        .table
            width: 100%

        

</style>